import { Component } from '@angular/core';

import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'app-oops',
    templateUrl: './oops.component.html',
    styleUrls: ['./oops.component.scss'],
    standalone: true,
    imports: [UIModule]
})
export class OopsComponent {}
